import React from "react"
import useMinimalConfig from "../../hooks/use-minimal-config"
import HeaderTitle from "./components/header-title"
import HeaderExternalLinks from "./components/header-external-links"
import { HeaderType } from "../../types"

type LayoutProps = {
  header: HeaderType
  [key: string]: any
}

const Header = ({ header, ...props }: LayoutProps) => {
  const { navigation: nav } = useMinimalConfig()

  return (
    <React.Fragment>
      <div className={`w-full ${header.advanced?.class || `h-14`}`} />
      <div className="fixed top-0 w-full z-50 bg-header shadow-md">
        <div className="container px-4 lg:px-0">
          <div className="flex justify-between items-center border-b-2 border-gray-100 py-2 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <HeaderTitle logo={header?.logo} logoLink={header?.logoLink} desktopHeight={header?.logoHeightLg || 30} mobileHeight={header?.logoHeightSm || 30} />
            </div>
            <div className="-mt-2 w-1/2 md:w-auto text-right">
              <HeaderExternalLinks links={header.links} {...props} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Header
